import apiEndpoints from "./endpoints/apiEndpoints";
import { API_TYPE, FORECAST_REQUEST_TYPE } from "./enums";
import { makeApiUrlPath } from "./helpers/apiHelpers";

const geoCoastLineUrlParams = (mapBounds, mapWidth, mapHeight) => ({
  service: "WMS",
  version: "1.3.0",
  request: "GetMap",
  layers: `Forecasting:uk_coast_wgs84_t`,
  bbox: mapBounds,
  width: mapWidth || 564,
  height: mapHeight || 768,
  CRS: "EPSG:4326",
  STYLES: "",
  format: "image/png",
  TRANSPARENT: true,
});

const geoServerUrlParams = (day, mapBounds, mapWidth, mapHeight) => ({
  service: "WMS",
  version: "1.3.0",
  request: "GetMap",
  layers: `Forecasting:Fcast_Day_${day}`,
  bbox: mapBounds,
  width: mapWidth || 564,
  height: mapHeight || 768,
  CRS: "EPSG:3857",
  STYLES: "",
  format: "image/png",
  TRANSPARENT: true,
});

export const getGeoServerAddress = (...params) =>
  makeApiUrlPath(
    API_TYPE.GEO_SERVER,
    ["geoserver", "Forecasting", "wms"],
    geoServerUrlParams(...params)
  );

export const getCoastLineGeoServerAddress = (...params) =>
  makeApiUrlPath(
    API_TYPE.GEO_SERVER,
    ["geoserver", "Forecasting", "wms"],
    geoCoastLineUrlParams(...params)
  );

const openStreetMapUrlParams = (postCode) => ({
  addressdetails: 1,
  q: postCode,
  countrycodes: "gb",
  format: "json",
  limit: 1,
});

export const getLatestForecastInfoServerAddress = () => {
  return makeApiUrlPath(API_TYPE.FORECAST_SERVER, [
    "ajax",
    "forecast_text_summary.php",
  ]);
};

export const getOpenStreetMapServerAddress = (postCode) =>
  makeApiUrlPath(
    API_TYPE.OPEN_STREET_MAP,
    ["search"],
    openStreetMapUrlParams(postCode)
  );

export const getForecastServerAddress = (dir, type, params) => {
  switch (dir) {
    case FORECAST_REQUEST_TYPE.UPDATE_FORECAST_MAP:
      return makeApiUrlPath(type, ["ajax", "update-forecast-map"], params);
    case FORECAST_REQUEST_TYPE.UPDATE_FORECAST_LOCATION_SUMMARY:
      return makeApiUrlPath(
        type,
        ["ajax", "update-forecast-location-summary-v2"],
        params
      );
    case FORECAST_REQUEST_TYPE.UPDATE_FORECAST_SITE_INFO_WINDOW:
      return makeApiUrlPath(
        type,
        ["ajax", "update-forecast-site-infowindow-v2"],
        params
      );
    default:
      throw "UNKNOWN TYPE";
  }
};

export const getTileServerAddress = () => apiEndpoints[API_TYPE.TILE_SERVER];
