import { API_TYPE } from "../enums";

const map = {
  [process.env.VUE_APP_LOCAL_HOSTNAME]: {
    [API_TYPE.GEO_SERVER]: process.env.VUE_APP_GEO_SERVER_LOCAL_API_ENDPOINT,
    [API_TYPE.FORECAST_SERVER]:
      process.env.VUE_APP_FORECAST_SERVER_LOCAL_API_ENDPOINT,
    [API_TYPE.TILE_SERVER]: process.env.VUE_APP_TILE_SERVER_LOCAL_API_ENDPOINT,
    [API_TYPE.OPEN_STREET_MAP]:
      process.env.VUE_APP_OPEN_STREET_MAP_LOCAL_API_ENDPOINT,
  },
  [process.env.VUE_APP_DEV_HOSTNAME]: {
    [API_TYPE.GEO_SERVER]: process.env.VUE_APP_GEO_SERVER_DEV_API_ENDPOINT,
    [API_TYPE.FORECAST_SERVER]:
      process.env.VUE_APP_FORECAST_SERVER_DEV_API_ENDPOINT,
    [API_TYPE.TILE_SERVER]: process.env.VUE_APP_TILE_SERVER_DEV_API_ENDPOINT,
    [API_TYPE.OPEN_STREET_MAP]:
      process.env.VUE_APP_OPEN_STREET_MAP_DEV_API_ENDPOINT,
  },

  [process.env.VUE_APP_STAGING_HOSTNAME]: {
    [API_TYPE.GEO_SERVER]: process.env.VUE_APP_GEO_SERVER_STAGING_API_ENDPOINT,
    [API_TYPE.FORECAST_SERVER]:
      process.env.VUE_APP_FORECAST_SERVER_STAGING_API_ENDPOINT,
    [API_TYPE.TILE_SERVER]:
      process.env.VUE_APP_TILE_SERVER_STAGING_API_ENDPOINT,
    [API_TYPE.OPEN_STREET_MAP]:
      process.env.VUE_APP_OPEN_STREET_MAP_STAGING_API_ENDPOINT,
  },
  [process.env.VUE_APP_PROD_HOSTNAME]: {
    [API_TYPE.GEO_SERVER]: process.env.VUE_APP_GEO_SERVER_PROD_API_ENDPOINT,
    [API_TYPE.FORECAST_SERVER]:
      process.env.VUE_APP_FORECAST_SERVER_PROD_API_ENDPOINT,
    [API_TYPE.TILE_SERVER]: process.env.VUE_APP_TILE_SERVER_PROD_API_ENDPOINT,
    [API_TYPE.OPEN_STREET_MAP]:
      process.env.VUE_APP_OPEN_STREET_MAP_PROD_API_ENDPOINT,
  },
};

const currentHostname = window?.location?.hostname || "INVALID";

const apiEndpoints = map[currentHostname];

if (!apiEndpoints) {
  console.log(map);
  throw new Error(`UNABLE TO MATCH CONFIG FOR HOSTMANE: "${currentHostname}"`);
}

export default apiEndpoints;
