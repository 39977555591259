import { icon } from "leaflet";
import { getForecastServerAddress } from "@/api/index";
import { API_TYPE, FORECAST_REQUEST_TYPE } from "@/api/enums";

export const mapEventHandlers = {
  async handleOnMarkerClick(siteId = null) {
    const updateForecastSiteInfoWindowResponse = await fetch(
      getForecastServerAddress(
        FORECAST_REQUEST_TYPE.UPDATE_FORECAST_SITE_INFO_WINDOW,
        API_TYPE.FORECAST_SERVER,
        {
          site_id: siteId,
        }
      )
    ).then((response) => response.json());

    if (updateForecastSiteInfoWindowResponse) {
      this.$store.commit(
        "updateMarkerData",
        updateForecastSiteInfoWindowResponse
      );
    }
  },

  async handleForecastUpdate() {
    const { _northEast, _southWest } = this.map.getBounds();

    const updateForecastMapResponse = await fetch(
      getForecastServerAddress(
        FORECAST_REQUEST_TYPE.UPDATE_FORECAST_MAP,
        API_TYPE.FORECAST_SERVER,
        {
          bounds: `${_southWest.lat},${_southWest.lng},${_northEast.lat},${_northEast.lng}`,
          day: this.day,
        }
      )
    ).then((response) => response.json());

    if (updateForecastMapResponse.length) {
      updateForecastMapResponse.map(
        ({ site_id, site_name, forecast_index, latitude, longitude }) =>
          this.markers.push({
            id: site_id,
            key: site_id,
            icon: icon({
              iconUrl: `../markers/marker_${forecast_index}.svg`,
              iconSize: [32, 32],
              iconAnchor: [16, 32],
            }),
            position: { lat: latitude, lng: longitude },
            tooltip: site_name,
            draggable: false,
            visible: true,
            popup: {
              text: site_name,
            },
          })
      );
    }
  },
  handleZoomChange(zoom) {
    this.markers = [];
    if (zoom >= 10) {
      this.handleForecastUpdate(this.searchData);
    }
    this.$store.commit("updateZoomLevel", zoom);
  },
};
