<template>
  <form
    class="w-50"
    @submit="
      (e) => {
        e.preventDefault();
        this.$emit('onSearch');
      }
    "
  >
    <div class="flex max-w-md">
      <input
        type="text"
        class="flex-grow py-0 px-2"
        :id="inputId"
        :placeholder="placeholder"
        :value="value"
      />
      <button type="submit" class="btn btn-dark search-button py-0as">
        Search
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "SearchForm",
  emits: ["onSearch"],
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    inputId: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
input {
  //padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}

button {
  padding: 0.3rem 1.5rem;
  color: var(--color-white);
  border-radius: 4px;
  background-color: var(--color-black);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  margin-left: 1rem;
}

input,
button {
  &:focus,
  &:active {
    outline: 2px solid var(--color-yellow-400);
  }
}
</style>
