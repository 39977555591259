<template>
  <div
    v-if="forecastData?.length"
    class="saved-location-forecast relative border-2 border-uk-grey-500 bg-white"
  >
    <button
      title="Clear default location"
      class="absolute top-0 right-0 cursor-pointer p-4"
      @click="removeDefaultLocation()"
    >
      <svg aria-hidden="true" width="20" height="20" viewBox="0 0 24 24">
        <circle
          rx="10"
          ry="10"
          radius="10"
          class="fill-black hover:fill-green-600"
        />
        <path
          fill="currentColor"
          class="hover:text-green-600"
          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
        />
      </svg>
    </button>

    <h3 class="border-bottom mt-0 mb-2 border-uk-grey-500 bg-uk-grey-400 p-3">
      <span class="ext-uk-grey-900 text-2xl">Default location</span>
    </h3>

    <div
      class="max-w-32 mx-3 flex flex-row items-start justify-between bg-uk-grey-400 py-3"
    >
      <div class="flex flex-col justify-start pl-4">
        <h3 class="mt-0 text-[2rem] font-bold text-uk-grey-900">
          {{ defaultSiteName }}
        </h3>
        <div class="flex flex-row gap-2">
          <div class="text-[18px] font-bold">{{ forecastData[0].day }}</div>
          <div class="text-[18px] text-gray-600">
            {{ forecastData[0].date }}
          </div>
        </div>
      </div>

      <div class="mr-[6px] flex w-[6.5rem] flex-col items-center gap-1">
        <img
          :src="forecastData[0].icon"
          width="64"
          :title="forecastData[0].pollutionLabel"
        />
        <div class="font-bold">{{ forecastData[0].band }}</div>
      </div>
    </div>

    <div class="flex gap-2 overflow-x-auto p-3">
      <div
        class="max-w-32 flex w-[6.5rem] min-w-[6.5rem] shrink-0 flex-col items-center justify-items-center bg-uk-grey-400 p-2"
        v-for="forecast in forecastData.slice(1)"
        :key="forecast.label"
      >
        <div class="text-[14px] font-bold">{{ forecast.day }}</div>
        <div class="mb-2 mt-[3px] whitespace-nowrap text-[14px] text-gray-600">
          {{ forecast.date }}
        </div>
        <div class="flex flex-col items-center gap-1">
          <img
            :src="forecast.icon"
            width="42"
            :title="forecast.pollutionLabel"
          />
          <div class="font-bold">{{ forecast.band }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getForecastServerAddress } from "@/api/index";
import { API_TYPE, FORECAST_REQUEST_TYPE } from "@/api/enums";
import { siteMixins } from "@/mixins/siteMixins";

export default {
  name: "SavedLocationForecast",
  props: {
    forecastData: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [siteMixins],
  computed: {
    savedSiteId() {
      return this.$store.state.savedSiteId;
    },
    defaultSiteName() {
      return this.$store.getters.getSavedSiteName;
    },
  },

  methods: {
    async getDefaultSiteForecast(siteId = null) {
      if (!siteId) {
        return;
      }

      const forecastResponse = await fetch(
        getForecastServerAddress(
          FORECAST_REQUEST_TYPE.UPDATE_FORECAST_SITE_INFO_WINDOW,
          API_TYPE.FORECAST_SERVER,
          {
            site_id: siteId,
          }
        )
      ).then((response) => response.json());

      if (forecastResponse) {
        this.$store.commit("updateSavedSiteForecast", forecastResponse);
      }
    },
  },
  watch: {
    savedSiteId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getDefaultSiteForecast(newValue);
      }
    },
  },

  mounted() {
    const savedSiteId = this.getCookieByName("ukaf_saved_location");
    if (savedSiteId) {
      this.getDefaultSiteForecast(savedSiteId);
    }
  },
};
</script>
