<template>
  <div class="flex grow flex-col gap-3 border-2 border-uk-grey-500 bg-white">
    <h3 class="border-bottom mt-0 border-uk-grey-500 bg-uk-grey-400 p-3">
      <span class="text-2xl font-bold text-uk-grey-900">Latest Forecast</span>
    </h3>
    <p class="px-3"><strong>Today: </strong> {{ today }}</p>
    <p class="px-3"><strong>Tomorrow: </strong> {{ tomorrow }}</p>
    <p class="px-3"><strong>Outlook: </strong> {{ outlook }}</p>
    <small class="px-3 py-1 text-gray-600">Issued at {{ issueDate }}</small>
  </div>
</template>

<script>
import { getLatestForecastInfoServerAddress } from "@/api";

export default {
  name: "LatestForecast",
  components: {},
  data() {
    return {
      today: "",
      tomorrow: "",
      outlook: "",
      issueDate: "",
    };
  },
  mounted() {
    fetch(getLatestForecastInfoServerAddress())
      .then((response) => response.json() || {})
      .then(async ({ today, tomorrow, outlook, issue_date }) => {
        this.today = today ?? "";
        this.tomorrow = tomorrow ?? "";
        this.outlook = outlook ?? "";
        this.issueDate = issue_date
          ? new Date(issue_date).toLocaleString()
          : "Pending";
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
