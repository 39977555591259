<template>
  <div class="relative flex flex-col border-2 border-uk-grey-500">
    <button
      title="Clear default location"
      class="saved-location-forecast__close-btn absolute top-0 right-0 cursor-pointer p-4"
      @click="close"
    >
      <svg aria-hidden="true" width="20" height="20" viewBox="0 0 24 24">
        <circle
          rx="10"
          ry="10"
          radius="10"
          class="fill-black hover:fill-green-600"
        />
        <path
          fill="currentColor"
          class="hover:text-green-600"
          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
        />
      </svg>
    </button>
    <h3
      class="border-bottom mt-0 border-uk-grey-500 bg-uk-grey-400 p-3 text-2xl"
      v-on:click="close"
    >
      <span class="text-lg font-bold text-uk-grey-900"
        >Select a default location near: {{ postCode }}</span
      >
    </h3>
    <div class="flex flex-col justify-start divide-y">
      <button
        class="group w-full px-3 py-1 hover:bg-uk-blue-500 hover:text-white disabled:bg-uk-blue-400 disabled:text-white"
        v-on:click="setDefaultLocation(item.site_id)"
        v-for="item in data"
        :value="item.site_id"
        :key="item.site_id"
        :disabled="getSavedSiteId === item.site_id"
      >
        <div class="flex grow justify-start gap-4 self-start text-lg">
          <img
            :src="getIcon(item.index)"
            width="36"
            :title="getPollutionLabel(item.index)"
          />
          <div class="flex flex-col items-start">
            <div class="mb-[4px] mt-[4px] text-[18px]">
              {{ item.site_name }}
            </div>
            <div
              class="mb-[4px] text-[16px] italic text-gray-600 group-hover:text-gray-200 group-disabled:text-gray-200"
            >
              {{ parseFloat(item.dist).toFixed(2) }} miles
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { POLLUTION_SEVERITIES } from "@/enums";
import { siteMixins } from "@/mixins/siteMixins";

export default {
  name: "ResultsList",
  props: {
    postCode: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
    },
  },
  mixins: [siteMixins],
  methods: {
    close() {
      this.$emit("clear-postcode");
    },
    getPollutionLabel(index) {
      return `${POLLUTION_SEVERITIES?.[index]} (Index ${index})`;
    },
    getIcon(index) {
      return `/markers/marker_${index}.svg`;
    },
  },
};
</script>
