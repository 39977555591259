<template>
  <h3 class="marker-forecast__heading">
    {{ getActiveMarkerSitename }}
  </h3>
  <div class="marker-forecast__container">
    <div
      v-for="item in getActiveMarkerSiteData"
      :key="item.label"
      class="marker-forecast__item"
    >
      <div>{{ item.label }}</div>
      <div>
        <div
          v-if="item.index"
          class="marker-forecast__item--tag"
          :style="{
            backgroundColor: getPollutionColourCode(item.index),
          }"
        >
          {{ getPollutionLabel(item.index) }}
        </div>
        <div class="marker-forecast__item--tag" v-else>No data</div>
      </div>
    </div>
  </div>

  <button
    v-on:click="setDefaultLocation(getActiveMarkerSiteId)"
    class="mt-2 flex w-full items-center justify-center rounded bg-green-600 py-2 px-4 font-bold text-white hover:bg-green-400"
    margin="0.5rem 0 0"
    width="100%"
    :disabled="getSavedSiteId === getActiveMarkerSiteId"
  >
    {{
      `${
        getSavedSiteId === getActiveMarkerSiteId ? "Marked as" : "Make"
      } default location`
    }}
  </button>
</template>

<script>
import { POLLUTION_INDEX_COLOUR_CODES } from "@/enums";
import { siteMixins } from "@/mixins/siteMixins";

export default {
  name: "MarkerContent",
  props: {
    markerData: {
      type: Object,
    },
  },
  mixins: [siteMixins],
  computed: {
    getActiveMarkerSitename() {
      return this.$props.markerData?.site_dataset?.site_name;
    },

    getActiveMarkerSiteId() {
      return this.$props.markerData?.site_dataset?.site_id;
    },
    getActiveMarkerSiteData() {
      let data = [];

      if (this.$props.markerData?.site_dataset?.data) {
        data = Object.values(this.$props.markerData?.site_dataset?.data);
      }
      return data;
    },
  },
  methods: {
    getPollutionColourCode(index) {
      return POLLUTION_INDEX_COLOUR_CODES?.[index];
    },
    getPollutionLabel(index) {
      return `${this.$props.markerData?.INDEXBAND[index]} (Index ${index})`;
    },
    getPollutionLabelColour(index) {
      return POLLUTION_INDEX_COLOUR_CODES?.[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.marker-forecast {
  &__container {
    min-width: 120px;
    border: 1px solid #ccc;
  }
  &__heading {
    font-size: 1.2rem;
    margin: 1rem 0 0.8rem;
  }

  &__item {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    & > div {
      padding: 0.4rem;
      width: 50%;
      font-size: 0.8rem;
    }

    & > div:first-of-type {
      border-right: 1px solid #ccc;
    }

    &--tag {
      padding: 0.2rem 0.3rem;
    }
  }
}
</style>
