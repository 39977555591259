export const API_TYPE = {
  GEO_SERVER: "GEO_SERVER",
  FORECAST_SERVER: "FORECAST_SERVER",
  TILE_SERVER: "TILE_SERVER",
  OPEN_STREET_MAP: "OPEN_STREET_MAP",
};
export const FORECAST_REQUEST_TYPE = {
  UPDATE_FORECAST_MAP: "UPDATE_FORECAST_MAP",
  UPDATE_FORECAST_LOCATION_SUMMARY: "UPDATE_FORECAST_LOCATION_SUMMARY",
  UPDATE_FORECAST_SITE_INFO_WINDOW: "UPDATE_FORECAST_SITE_INFO_WINDOW",
};
