<template>
  <div>
    <SectionWithIcon icon="search">
      <div>
        <label for="postcode" class="block"
          >Enter your location or postcode to zoom in.</label
        >
        <SearchForm
          v-bind:value="postCode"
          v-on:input="postCode = $event.target.value"
          :placeholder="'OX11'"
          inputId="postcode"
          v-on:onSearch="callApi()"
        />
      </div>
    </SectionWithIcon>
    <div v-if="forecastData.length > 0" class="mt-4">
      <ResultsList
        :data="forecastData"
        :postCode="postCode"
        @clear-postcode="clearPostcode"
      />
    </div>
  </div>
</template>

<script>
import { SearchForm, ResultsList } from "@/components/Molecules";
import { API_TYPE, FORECAST_REQUEST_TYPE } from "@/api/enums";
import { getForecastServerAddress, getOpenStreetMapServerAddress } from "@/api";
import SectionWithIcon from "@/components/Molecules/SectionWithIcon.vue";

export default {
  name: "PostcodeSearch",
  emits: ["onPostcodeSearchResponse"],
  components: {
    SectionWithIcon,
    ResultsList,
    SearchForm,
  },
  props: [],
  data() {
    return {
      postCode: "",
      mapData: {
        lat: 0,
        lon: 0,
        boundingBox: [],
      },
      forecastData: [],
    };
  },
  watch: {
    urlParam(newValue) {
      // Handle the updated URL parameter value here
      console.log("URL parameter updated:", newValue);
    },
  },
  created() {
    // Initial setting of the URL parameter value
    this.urlParam = this.getURLParameter("postcode");

    // Watch for changes to the URL using onpopstate
    window.onpopstate = () => {
      this.urlParam = this.getURLParameter("postcode");
    };
    console.log("urlParam", this.urlParam);
    // Update postcode if urlParam is not nu
    if (this.urlParam) {
      this.postCode = this.urlParam;
      this.callApi();
    }
  },
  methods: {
    getURLParameter(name) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    },
    callApi() {
      if (this.postCode.length > 0) {
        fetch(getOpenStreetMapServerAddress(this.postCode))
          .then((response) => response.json())
          .then(async (response) => {
            if (response.length > 0) {
              const data = response[0];
              this.mapData.lat = data.lat;
              this.mapData.lon = data.lon;
              this.mapData.boundingBox = data.boundingbox;

              await this.getForecastLocationSummary(data.lat, data.lon);

              this.$emit("onPostcodeSearchResponse", this.mapData);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // implement toaster to present error
      }
    },
    clearPostcode() {
      this.postCode = "";
      this.forecastData = [];
    },
    async getForecastLocationSummary(lat, lon) {
      const forecastLocationSummaryResponse = await fetch(
        getForecastServerAddress(
          FORECAST_REQUEST_TYPE.UPDATE_FORECAST_LOCATION_SUMMARY,
          API_TYPE.FORECAST_SERVER,
          {
            location: `${lat},${lon}`,
            day: this.day,
          }
        )
      ).then((response) => response.json());
      console.log(forecastLocationSummaryResponse);
      this.forecastData =
        forecastLocationSummaryResponse.table?.nearest_sites_dataset;
    },
  },
};
</script>
