export const POLLUTION_INDEX_COLOUR_CODES = {
  0: "#bbbbbb",
  1: "#9cff9c",
  2: "#31ff00",
  3: "#31cf00",
  4: "#ffff00",
  5: "#ffcf00",
  6: "#ff9a00",
  7: "#ff9292",
  8: "#ff0000",
  9: "#990000",
  10: "#ce30ff",
};

export const POLLUTION_SEVERITIES = {
  0: "NO DATA",
  1: "LOW",
  2: "LOW",
  3: "LOW",
  4: "MODERATE",
  5: "MODERATE",
  6: "MODERATE",
  7: "HIGH",
  8: "HIGH",
  9: "HIGH",
  10: "VERY HIGH",
};
