<template>
  <div class="w-full">
    <div class="flex flex-col gap-3">
      <div class="flex flex-col gap-3 lg:flex-row">
        <LatestForecast />
        <SavedLocationForecast :forecastData="savedSiteForecastData" />
      </div>
      <div class="min-w-[350px]">
        <MapView
          :position="position"
          :zoom="zoom"
          :day="day"
          @onDaySelectionChange="handleDaySelectionChange"
        />

        <div>
          <PollutionForecast />
        </div>
      </div>
    </div>
    <PostcodeSearch @onPostcodeSearchResponse="handlePostcodeSearch" />
    <div class="my-6">
      <ProvideBy />
    </div>
  </div>
</template>

<script>
import LatestForecast from "./components/Organisms/Map/LatestForecast.vue";
import { SavedLocationForecast } from "@/components/Molecules";
import {
  PostcodeSearch,
  PollutionForecast,
  MapView,
} from "@/components/Organisms";
import ProvideBy from "@/components/Organisms/ProvideBy.vue";

const initialZoom = 6;
const initialPosition = [55.39479553344443, -3.655448334103866];

export default {
  name: "App",
  components: {
    ProvideBy,
    MapView,
    PostcodeSearch,
    LatestForecast,
    PollutionForecast,
    SavedLocationForecast,
  },
  data() {
    return {
      position: initialPosition,
      zoom: initialZoom,
      day: 1,
      urlParam: null,
    };
  },
  computed: {
    savedSiteForecastData() {
      return this.$store.getters.getSavedSiteForecast;
    },
  },
  methods: {
    handlePostcodeSearch(mapData) {
      this.position = [mapData.lat, mapData.lon];
    },
    handleDaySelectionChange(e) {
      this.day = e.target.value;
    },
  },
};
</script>

<style lang="scss">
// typograpy
p {
  margin: 0 0 1rem;
}

.mid-text {
  padding: 10px;
  margin-left: 10px;
  vertical-align: middle;
  height: 50px;
  width: auto;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
