<template>
  <div class="flex gap-2">
    <CalendarIcon v-if="icon === 'calendar'" class="h-12 w-12 shrink-0" />
    <LayersIcon v-if="icon === 'layers'" class="h-12 w-12 shrink-0" />
    <SearchIcon v-if="icon === 'search'" class="mt-5 h-12 w-12 shrink-0" />
    <div class="sm:mt-3 mt-2 w-full">
      <slot />
    </div>
  </div>
</template>

<script>
import CalendarIcon from "@/components/icons/CalendarIcon.vue";
import LayersIcon from "@/components/icons/LayersIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";

export default {
  name: "SectionWithIcon",
  components: {
    CalendarIcon,
    LayersIcon,
    SearchIcon,
  },
  props: {
    icon: {
      validator(value) {
        return ["calendar", "layers", "search"].includes(value);
      },
    },
  },
};
</script>

<style scoped></style>
