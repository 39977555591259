import { store } from "@/store/store";

export const siteMixins = {
  computed: {
    getSavedSiteId() {
      return this.$store.getters.getSavedSiteId;
    },
  },
  methods: {
    setDefaultLocation: (siteId = null) => {
      const expiryDate = new Date();

      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      try {
        document.cookie = `ukaf_saved_location=${siteId}; expires=${expiryDate};`;
        store.commit("updateSelectedSiteId", siteId);
      } catch (e) {
        console.warn(e);
      }
    },
    removeDefaultLocation: () => {
      document.cookie =
        "ukaf_saved_location" +
        "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      store.commit("resetSavedSite");
    },
    getCookieByName(cookieName) {
      const name = cookieName + "=";
      const cDecoded = decodeURIComponent(document.cookie);
      const cArr = cDecoded.split("; ");
      let res;
      cArr.forEach((val) => {
        if (val.indexOf(name) === 0) {
          res = val.substring(name.length);
        }
      });
      return res;
    },
  },
};
