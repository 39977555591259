<template>
  <div class="align-items-center flex-col">
    <p class="mt-2 block text-lg font-bold lg:hidden">
      Forecast layer transparency
    </p>
    <Slider
      class="slider-color mt-9 w-full min-w-[200px] max-w-md"
      v-model="transparencyValue"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      @change="handleOpacityChange($event)"
    />
    <p class="mt-2 hidden font-bold lg:block">Forecast layer transparency</p>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";

export default {
  name: "LayerTransparency",
  components: {
    Slider,
  },
  data() {
    return {
      min: 0,
      max: 100,
      step: 1,
      transparencyValue: 100,
      disabled: false,
    };
  },
  computed: {
    getMapOverlayOpacity() {
      return this.$store.state.forecastMapOverlayOpacity;
    },
    getDisabled() {
      return this.$store.getters.getIsOpacityOverwritten;
    },
  },
  methods: {
    handleOpacityChange(opacityLevel) {
      this.$store.commit("updateForecastMapOverlayOpacity", opacityLevel / 100);
    },
  },

  watch: {
    getMapOverlayOpacity() {
      this.transparencyValue = this.getMapOverlayOpacity * 100;
    },
    getDisabled() {
      this.disabled = this.getDisabled;
    },
  },

  mounted() {
    this.transparencyValue = this.getMapOverlayOpacity * 100;
  },
};
</script>
