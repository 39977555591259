import { createStore } from "vuex";
import { POLLUTION_INDEX_COLOUR_CODES } from "@/enums";

export const store = createStore({
  state: {
    activeMarkerData: {},
    savedSiteId: null,
    defaultSiteForecast: {},
    forecastMapOverlayOpacity: 1,
    zoomLevel: 1,
  },
  mutations: {
    updateSavedSiteForecast(state, payload) {
      //state.defaultSiteForecast = payload;
      if (!payload?.INDEXBAND) {
        return;
      }
      const bandMap = new Map(
        payload.INDEXBAND.map((val, index) => [index.toString(), val])
      );

      const data = Object.values(payload.site_dataset?.data).map((x) => ({
        ...x,
        band: bandMap.get(x.index),
        pollutionLabel: `${bandMap.get(x.index)} (${x.index})`,
        color: POLLUTION_INDEX_COLOUR_CODES?.[x.index],
        icon: `/markers/marker_${x.index}.svg`,
        day: x.label.split(" ")[0],
        date: x.label.split(" ").slice(1).join(" "),
      }));
      state.defaultSiteForecast = {
        ...payload,
        data,
      };
    },
    updateMarkerData(state, payload) {
      state.activeMarkerData = payload;
    },
    updateZoomLevel(state, payload) {
      state.zoomLevel = payload;
    },
    updateSelectedSiteId(state, payload) {
      state.savedSiteId = payload;
    },
    updateForecastMapOverlayOpacity(state, payload) {
      state.forecastMapOverlayOpacity = payload;
    },
    resetSavedSite(state) {
      state.defaultSiteForecast = {};
    },
  },
  getters: {
    getSavedSiteId(state) {
      return state.defaultSiteForecast?.site_dataset?.site_id;
    },
    getSavedSiteName(state) {
      return state.defaultSiteForecast?.site_dataset?.site_name;
    },
    getZoomAdjustedOpacity(state) {
      return state.zoomLevel > 9 ? 0.25 : state.forecastMapOverlayOpacity;
    },
    getIsOpacityOverwritten(state) {
      return state.zoomLevel > 9;
    },
    getSavedSiteForecast(state) {
      return state.defaultSiteForecast.data;
    },
  },
});
