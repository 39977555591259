<template>
  <div class="select-menu__wrapper">
    <select class="select-menu text-[14px]">
      <option v-for="option in options" :value="option.id" :key="option.id">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectMenu",
  props: {
    value: null,
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  mounted() {
    this.selectedOption = this.value;
  },

  watch: {
    value(newValue) {
      this.selectedOption = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.select-menu {
  width: 100%;
  appearance: none;
  border: 1px solid var(--color-gray-200);
  border-radius: 3px;
  height: 32px;
  padding: 0 2rem 0 0.5rem;

  &:focus,
  &:active {
    outline: 2px solid var(--color-yellow-400);
  }

  &__wrapper {
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid var(--color-gray-600);
      position: absolute;
      right: 11px;
      top: 12px;
      z-index: 10;
      pointer-events: none;
    }
  }
}
</style>
