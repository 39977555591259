<template>
  <div class="relative w-full min-w-full overflow-hidden" ref="mapContainer">
    <l-map
      ref="mapRef"
      :minZoom="minZoom"
      class="min-h-[600px] lg:min-h-[800px]"
      :maxZoom="maxZoom"
      :maxBounds="maxBounds"
      v-on:update:zoom="handleZoomChange($event)"
      v-model:zoom="mapZoomLevel"
      v-model:center="mapPosition"
      @ready="localMapInstance()"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :visible="marker.visible"
        :draggable="marker.draggable"
        :lat-lng="marker.position"
        :icon="marker.icon"
        id="marker.id"
        :ref="`marker_${marker.id}`"
        @click="handleOnMarkerClick(marker.id)"
      >
        <l-popup :options="{ autoPan: false }">
          <MarkerContent :marker-data="getActiveMarkerSiteData" />
        </l-popup>
      </l-marker>
      <l-control :position="'topright'" class="z-50">
        <div>
          <DaySelection @onDaySelectionChange="passBackDayChange" />
        </div>
      </l-control>
      <l-control :position="'bottomleft'">
        <div class="mb-4 lg:mb-0">
          <LayerTransparency />
        </div>
      </l-control>
    </l-map>
    <div
      ref="loadingIndicator"
      :class="`absolute left-[4.5rem] top-[1rem] z-[5000] bg-gray-700 px-4 text-white ${
        isLoading ? 'block' : 'hidden'
      }`"
    >
      Loading...
    </div>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControl,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { getTileServerAddress } from "@/api";
import LayerTransparency from "@/components/Molecules/LayerTransparency.vue";
import { mapEventHandlers } from "./js/mapEventHandlers";

import MarkerContent from "./MarkerContent.vue";
import { tileLayer } from "leaflet/src/layer";
import apiEndpoints from "@/api/endpoints/apiEndpoints";
import { API_TYPE } from "@/api/enums";
import { DaySelection } from "@/components/Molecules";

const MAX_BOUNDS = [
  [49.5, -8.6],
  [61.2, 2],
];

export default {
  name: "MapView",
  components: {
    DaySelection,
    LayerTransparency,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControl,
    MarkerContent,
  },
  emits: ["onDaySelectionChange"],
  props: ["position", "zoom", "day"],
  data() {
    return {
      resizeTimeoutId: null,
      url: getTileServerAddress(),
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      maxBounds: MAX_BOUNDS,
      maxBoundsViscosity: 1,
      mapPosition: this.position,
      mapZoomLevel: this.zoom,
      maxZoom: 14,
      lastLayer: null,
      minZoom: 6,
      markers: [],
      forecastLayer: null,
      map: null,
      isLoading: false,
    };
  },
  computed: {
    getActiveMarkerSiteData() {
      return this.$store.state.activeMarkerData;
    },
    opacity() {
      return this.$store.getters.getZoomAdjustedOpacity;
    },
  },
  methods: {
    passBackDayChange(day) {
      this.$emit("onDaySelectionChange", day);
    },
    onLoading() {
      if (this.isLoading !== this.forecastLayer.isLoading()) {
        this.isLoading = this.forecastLayer.isLoading();
      }
    },
    addForecastLayer(day) {
      const server = apiEndpoints[API_TYPE.GEO_SERVER];
      const url = `${server}/geoserver/Forecasting/wms`;
      this.forecastLayer = tileLayer.wms(url, {
        layers: `Forecasting:Fcast_Day_${day}`,
        opacity: this.$store.getters.getZoomAdjustedOpacity,
        zIndex: 2,
        transparent: true,
        format: "image/png",
        query: "countrycode=GB",
      });

      this.forecastLayer.addTo(this.map);

      this.forecastLayer.on("tileload", this.onLoading);

      this.forecastLayer.on("tileloadstart", this.onLoading);

      this.forecastLayer.on("load", this.onLoading);
    },
    localMapInstance() {
      this.map = this.$refs.mapRef.leafletObject;
      if (this.forecastLayer === null) {
        this.addForecastLayer(this.day);
      }
      setTimeout(() => {
        this.map.invalidateSize();
      }, 100);
      setTimeout(() => {
        this.map.invalidateSize();
      }, 1000);
    },
    handleResize() {
      if (this.resizeTimeoutId) {
        window.clearTimeout(this.resizeTimeoutId);
      }
      this.resizeTimeoutId = setTimeout(() => this.map.invalidateSize(), 250);
    },
    ...mapEventHandlers,
  },
  watch: {
    day(newVal) {
      if (this.forecastLayer !== null) {
        this.forecastLayer.remove();
      }
      this.addForecastLayer(newVal);
    },
    position(newVal) {
      this.map.setView(newVal, 14, { animation: true });
    },
    mapZoomLevel(newVal) {
      this.mapZoomLevel = newVal;
    },
    opacity(newVal) {
      if (this.forecastLayer !== null) {
        this.forecastLayer.setOpacity(newVal);
      }
    },
  },
  mounted() {
    window.onresize = this.handleResize;
  },
};
</script>

<style lang="scss">
.leaflet-layer {
  mix-blend-mode: multiply;
}
</style>
