<template>
  <div className="pollution-forecast-container">
    <div className="indexband">
      <div
        className="indexband__level "
        :style="{ backgroundColor: getPollutionLabelColour(1) }"
      >
        1
      </div>
      <div
        className="indexband__level "
        :style="{ backgroundColor: getPollutionLabelColour(2) }"
      >
        2
      </div>
      <div
        className="indexband__level "
        :style="{
          backgroundColor: getPollutionLabelColour(3),
          borderRight: '2px solid #2a9a08',
        }"
      >
        3
      </div>
      <div
        className="indexband__level "
        :style="{ backgroundColor: getPollutionLabelColour(4) }"
      >
        4
      </div>
      <div
        className="indexband__level "
        :style="{ backgroundColor: getPollutionLabelColour(5) }"
      >
        5
      </div>
      <div
        className="indexband__level "
        :style="{
          backgroundColor: getPollutionLabelColour(6),
          borderRight: '2px solid #da8608',
        }"
      >
        6
      </div>
      <div
        className="indexband__level "
        :style="{ backgroundColor: getPollutionLabelColour(7) }"
      >
        7
      </div>
      <div
        className="indexband__level "
        :style="{
          color: '#fff',
          backgroundColor: getPollutionLabelColour(8),
        }"
      >
        8
      </div>
      <div
        className="indexband__level "
        :style="{
          color: '#fff',
          backgroundColor: getPollutionLabelColour(9),
          borderRight: '2px solid #d48dea',
        }"
      >
        9
      </div>
      <div
        className="indexband__level "
        :style="{
          color: '#fff',
          backgroundColor: getPollutionLabelColour(10),
        }"
      >
        10
      </div>
    </div>
    <div className="indexband indexband__lower">
      <div className="indexband__level--text">Low</div>
      <div className="indexband__level--text">Moderate</div>
      <div className="indexband__level--text">High</div>
      <div className="indexband__level--text">Very High</div>
    </div>
  </div>
</template>

<script>
import { POLLUTION_INDEX_COLOUR_CODES } from "@/enums";

export default {
  name: "PollutionForecast",
  components: {},
  data() {
    return {};
  },

  methods: {
    getPollutionLabelColour(index) {
      return POLLUTION_INDEX_COLOUR_CODES?.[index];
    },
  },
};
</script>

<style lang="scss">
h2 {
  text-align: left;
  font-size: 20pt;
  font-weight: bold;
}

.indexband {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;

  &__level {
    background: transparent;
    color: var(--color-black);
    flex: 1;
    max-width: 7.2%;
    padding: 0.3rem;
    text-align: center;
    font-weight: bold;

    @media (min-width: 425px) {
      min-width: 20px;
    }

    &:last-child {
      max-width: none;
      min-width: 80px;
    }

    &--text {
      font-size: unset;
      text-align: center;
      width: 24.66%;
      font-weight: bold;

      @media (min-width: 425px) {
        width: 26.5%;
      }

      &:last-child {
        width: 25%;

        @media (min-width: 425px) {
          width: 20%;
        }
      }
    }
  }

  &__lower {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
