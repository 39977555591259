<template>
  <SelectMenu
    class="max-w-md"
    id="day-select"
    @change="$emit('onDaySelectionChange', $event)"
    :options="DAY_OPTIONS"
  />
</template>

<script>
import { format, addDays } from "date-fns";
// componnets
import { SelectMenu } from "@/components/Atoms";

const getDayOption = (numberOfDaysToAdd) => {
  const date = addDays(new Date(), numberOfDaysToAdd);
  return {
    id: numberOfDaysToAdd + 1,
    label: `${numberOfDaysToAdd ? format(date, "EEEE") : "Today"} (${format(
      date,
      "do MMMM yyyy"
    )})`,
  };
};

const DAY_OPTIONS = [
  getDayOption(0),
  getDayOption(1),
  getDayOption(2),
  getDayOption(3),
  getDayOption(4),
];

export default {
  name: "DaySelection",
  components: {
    SelectMenu,
  },
  data() {
    return {
      DEFAULT_SELECTION: DAY_OPTIONS[0],
      DAY_OPTIONS,
    };
  },
};
</script>
