import apiEndpoints from "../endpoints/apiEndpoints";

const makePath = (dir) =>
  dir.reduce((acc, path) => {
    if (dir) {
      return `${acc}/${path}`;
    }
    return acc;
  }, "");

const joinUrlParams = (urlParams) =>
  Object.keys(urlParams)
    .filter((param) => urlParams[param] !== undefined)
    .reduce(
      (acc, key) => `${acc}${acc == "" ? "?" : "&"}${key}=${urlParams[key]}`,
      ""
    );

export const makeApiUrlPath = (type, dir, params) =>
  `${apiEndpoints[type]}${dir ? makePath(dir) : ""}${
    params ? joinUrlParams(params) : ""
  }`;
