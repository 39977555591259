<template>
  <div class="">
    <hr />
    <p class="whitespace-nowrap py-5 text-gray-700">
      Forecast provided by the
      <img
        alt="Met Office"
        class="inline"
        src="/images/met_logo.png"
        width="125"
        height="24.375"
      />
    </p>
    <hr />
  </div>
</template>

<script>
export default {
  name: "ProvideBy",
};
</script>

<style></style>
